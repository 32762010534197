
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
      font-family: 'Bricolage Grotesque', sans-serif;
    }
  }